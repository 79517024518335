<template>
	<el-dialog
		:title="title"
		:visible.sync="visible"
		:lock-scroll="false"
		width="40%"
		@close="close"
		center
	>
		<div class="content">
			<div class="form">
				<el-form>
					<el-form-item label="成交公告天数：">
						<el-input style="width: 240px" type="number" v-model.number="form.cjggts"></el-input>
					</el-form-item>
					<el-form-item label="挂牌公告天数：">
						<el-input style="width: 240px" type="number" v-model.number="form.gpggts"></el-input>
					</el-form-item>
					<el-form-item label="最短竞价天数：">
						<el-input style="width: 240px" type="number" v-model.number="form.jjts"></el-input>
					</el-form-item>
          <el-form-item label="监督方名称：">
						<el-input style="width: 240px" v-model.number="form.jdfmc"></el-input>
					</el-form-item>
          <el-form-item label="监督方联系方式：">
						<el-input style="width: 240px" v-model.number="form.jdflxfs"></el-input>
					</el-form-item>
					<el-form-item label="行政区：">
						<el-cascader
							style="width: 240px"
							v-model="form.xzqbh"
							:props="prop"
							:options="regionOptions"
							:show-all-levels="false"
						></el-cascader>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button style="width: 116px" @click="close">取消</el-button>
			<el-button type="success" style="width: 150px" @click="submit">提交</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { addGgsj, editGgsj } from '@/api/projectManage'
export default {
	data() {
		return {
			title: '新增公告时间',
			type: 'add',
			form: {
				cjggts: null,
				gpggts: null,
				jjts: null,
				xzqbh: '',
        jdfmc: null,
        jdflxfs: '',
				id: null,
			},
			prop: {
				label: 'areaName',
				children: 'children',
				value: 'id',
				fullPathName: 'fullPathName',
				checkStrictly: true,
				emitPath: false,
			},
			checked: false,
		}
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		// data: {
		// 	type: Object,
		// 	default: function () {
		// 		return {}
		// 	},
		// },
		regionOptions: {
			type: Array,
			default: function () {
				return []
			},
		},
	},
	methods: {
		async submit() {
			// 提交
			let res = {}
			if (this.type == 'add') {
				res = await addGgsj(this.form)
			} else if (this.type == 'edit') {
				res = await editGgsj(this.form)
			}
			let { status, data, message } = res
			if (status == 200) {
				this.$message.success(message)
				this.close()
			} else {
				this.$message.error(message)
			}
		},
		close() {
			// 关闭
			this.$emit('close', false)
		},
	},
}
</script>

<style lang="less" scoped>
/deep/.el-form-item {
	display: flex;
}
/deep/.el-form-item__label {
	min-width: 120px;
	text-align: right;
}
</style>
